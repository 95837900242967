import { observer } from 'mobx-react'
import Page from '../../components/Page'
import classNames from 'classnames'
import { Button } from '../../components/Button'
import { EventListItemData, Events3 } from './Events3'
import { useTranslation } from 'react-i18next'
import { Calendar } from '../../components/Calendar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faChevronDown,
  faEllipsisV,
  faEllipsisVertical,
  faFilter,
  faHouse,
  faList,
  faPrint,
  faTag,
} from '@fortawesome/free-solid-svg-icons'
import { useContext, useEffect, useState } from 'react'
import { EVENT3_TYPE, Event3StoreContext } from '../../store/event3Store'
import { Moment } from 'moment'
import { Loading } from '../../components/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import { UserStoreContext } from '../../store/userStore'
import { USER_ROLE_TYPE } from '../../Enums/TyraEnums'
import ModalRegular from '../../components/ModalRegular'
import { MeetingsToBook } from './components/MeetingsToBook'
import {
  faEnvelopeDot,
  faMemoCircleCheck,
} from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'
import { useClickOutside } from '../../hooks/useClickoutside'
import { ButtonDropdown } from '../../components/Dropdown'
import { NavigationStoreContext } from '../../store/navigationStore'
import { DepartmentStoreContext } from '../../store/departmentStore'
import { AnimatePresence, motion } from 'framer-motion'
import { TagStoreContext } from '../../store/tagStore'
import { useEventTypeSelections } from './CreateEvent3'
import { Slider } from '../../components/Slider'
import ListviewSelections from '../../components/ListviewSelections'
import { FileJobStoreContext, StaffEventsRead } from '../../store/fileJobStore'
import i18n from '../../i18nextConfig'
import SwalCustom from '../../components/SwalCustom'
import Swal from 'sweetalert2'
import { DateChooser } from '../blog/components/DateChooser'

export const EventsStartPage: React.FC = observer(() => {
  const { t } = useTranslation()
  const [showUnread, setShowUnread] = useState<boolean>(false)
  const [animate, setAnimate] = useState(false)
  const { schoolId } = useParams()
  const [closestEvent, setClosestEvent] = useState<
    EventListItemData | undefined
  >(undefined)
  //const [currentMonth, setCurrentMonth] = useState<Moment>(moment())
  const {
    createEvent,
    getEvents,
    events,
    meetingsToBook,
    currentMeetings,
    getDetailedEvent,
    currentMonth,
    changeCurrentMonth,
    eventsLoading,
    setSelectedDepartmentIds,
    selectedDepartmentIds,
    saveFilters,
    selectedTypes,
    setSelectedTypes,
    godMode,
    setGodMode,
    typeOfStaffEvent,
    setTypeOfStaffEvent,
  } = useContext(Event3StoreContext)

  const [dropdownVisible, setDropdownVisible] = useState(false)
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }
  const [menuRef, togglerRef] = useClickOutside(() => {
    setDropdownVisible(false)
  })

  const [generatingPrint, setGeneratingPrint] = useState(false)

  const [printStartDate, setPrintStartDate] = useState<Date | null>(null)
  const [printEndDate, setPrintEndDate] = useState<Date | null>(null)

  const [showPrint, setShowPrint] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [openDepartments, setOpenDepartments] = useState(false)
  const [openTags, setOpenTags] = useState(false)
  const [openType, setOpenType] = useState(true)

  const [previousDepartmentIds, setPreviousDepartmentIds] =
    useState<any>(undefined)
  const [previousTagIds, setPreviousTagIds] = useState<any>(undefined)
  const [previousTypes, setPreviousTypes] = useState<any>(undefined)
  const [previousGodMode, setPreviousGodMode] = useState<any>(undefined)

  const [currentFileJobId, setCurrentFileJobId] = useState<string | null>(null)

  const { currentUserRole } = useContext(UserStoreContext)
  const { setTitle } = useContext(NavigationStoreContext)

  const {
    informationTags,
    importantInformationTags,
    activityTags,
    groupMeetingTags,
    meetingTags,
    allTagsForEvent,
    selectedTagIds,
    setSelectedTagIds,
  } = useContext(TagStoreContext)

  const { exportEventList, enableProclaimInFileJob, fetchCurrentFileJob } =
    useContext(FileJobStoreContext)

  var myTimeout: string | number | NodeJS.Timer | undefined
  useEffect(() => {
    let elapsed = 0
    const timeoutDuration = 20000

    if (currentFileJobId) {
      myTimeout = setInterval(() => {
        fetchCurrentFileJob(currentFileJobId).then((response) => {
          if (response && response.file?._url) {
            window.open(response.file?._url, '_blank')
            setGeneratingPrint(false)
            setCurrentFileJobId(null)
            clearInterval(myTimeout)
          }
        })
        elapsed += 1000
        if (elapsed >= timeoutDuration) {
          clearInterval(myTimeout)
          setGeneratingPrint(false)
          setCurrentFileJobId(null)

          Swal.fire({
            text: t('blog.workingOnIt'),
            icon: 'info',
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#7eb5f4',
            cancelButtonColor: '#d33',
            confirmButtonText: t('general.ok'),
          })
        }
      }, 1000)
    }

    return () => clearInterval(myTimeout)
  }, [currentFileJobId])

  const { departments } = useContext(DepartmentStoreContext)

  const { changeSelectedCalendarDate, selectedCalendarDate, setEventsAsRead } =
    useContext(Event3StoreContext)
  const navigate = useNavigate()

  const handleShowUnread = () => {
    setShowUnread(!showUnread)
  }
  const handleShowAll = () => {
    setShowUnread(!showUnread)
  }

  const [openMeetingsToBook, setOpenMeetingsToBook] = useState(false)

  const eventTypeSelections = useEventTypeSelections()

  useEffect(() => {
    setTitle(t('menu.events'))
  }, [])

  useEffect(() => {
    if (!selectedDepartmentIds) {
      setSelectedDepartmentIds(departments?.map((dep) => dep.id))
    }
    if (!selectedTagIds) {
      setSelectedTagIds(allTagsForEvent?.map((tag) => tag.id))
    }
  }, [departments])
  useEffect(() => {
    if (showUnread) {
      // Start animation when showUnread becomes true
      setAnimate(true)
    } else {
      // Stop animation when showUnread becomes false
      setAnimate(false)
    }
  }, [showUnread, events])

  useEffect(() => {
    if (events && events.length) {
      setClosestEvent(
        events?.reduce((closest, event) => {
          const today = moment()
          const closestDiff = Math.abs(moment(closest.eventDate).diff(today))
          const eventDiff = Math.abs(moment(event.eventDate).diff(today))

          return eventDiff < closestDiff ? event : closest
        }),
      )
    }
  }, [events])

  useEffect(() => {
    getEvents()
  }, [currentMonth])

  const containerVariants = {
    hidden: { height: 0, opacity: 0 },
    visible: {
      height: 'auto',
      opacity: 1,
      transition: {
        type: 'tween',
        //duration: 0.5,
        //when: 'beforeChildren', // Ensure container animates before children
        staggerChildren: 0.03, // Stagger children animations
      },
    },
    exit: { height: 0, opacity: 0, transition: { duration: 0.3 } },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  }

  return (
    <Page>
      <div className="flex flex-col h-[100%] bg-[#F6F6F6]">
        <div
          className={classNames(
            `w-full py-4 text-white bg-[#7eb5f4] flex justify-between items-center`,
          )}
        >
          <div className="w-1/5 pl-4">
            <Button
              variant="custom"
              textColor="7eb5f4"
              size="sm"
              fontSize="font-semibold"
              label={t('general.close')}
              onClick={() => {
                navigate('/')
              }}
            />
          </div>
          <div className=" flex w-3/5 justify-center text-2xl font-bold">
            {t('menu.events')}
          </div>
          <div className="flex justify-end w-1/5 items-center pr-3">
            {currentUserRole &&
              (currentUserRole.role_type >= USER_ROLE_TYPE.TEACHER ||
                currentUserRole.is_upload_document_parent === true) && (
                <div className="pr-4">
                  <Button
                    variant="custom"
                    textColor="7eb5f4"
                    size="sm"
                    fontSize="font-semibold"
                    label={t('events.eventCreate') + ' +'}
                    onClick={() => {
                      navigate('/events/create/' + schoolId)
                    }}
                  />
                </div>
              )}
            {/* This will be used later dont remove */}
            {(process.env.REACT_APP_PUBLIC_URL?.includes('localhost') ||
              process.env.REACT_APP_PUBLIC_URL?.includes('alfa')) && (
              <>
                <div className="relative items-center flex">
                  <button
                    type="button"
                    className="w-full rounded-md group focus:outline-none hover:scale-110"
                    aria-haspopup="true"
                    aria-expanded={dropdownVisible}
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleDropdown()
                    }}
                    ref={togglerRef}
                  >
                    <FontAwesomeIcon
                      icon={faEllipsisVertical}
                      className="text-white px-4"
                    ></FontAwesomeIcon>
                  </button>

                  {dropdownVisible && (
                    <div
                      className="absolute right-5 top-3 z-50 mx-3 origin-top divide-y font-bold w-[280px]"
                      style={{
                        display: dropdownVisible ? 'block' : 'none',
                      }}
                      ref={menuRef}
                    >
                      <ButtonDropdown.Menu>
                        <ButtonDropdown.Section>
                          <>
                            <ButtonDropdown.Button
                              icon={faPrint}
                              label={t('blog.print')}
                              className="text-purple-300 "
                              onClick={(e) => {
                                e.stopPropagation()
                                setShowPrint(true)
                                setPrintStartDate(
                                  new Date(
                                    moment(currentMonth)
                                      .startOf('month')
                                      .format('YYYY-MM-DD'),
                                  ),
                                )
                                setPrintEndDate(
                                  new Date(
                                    moment(currentMonth)
                                      .endOf('month')
                                      .format('YYYY-MM-DD'),
                                  ),
                                )
                                setPreviousDepartmentIds(selectedDepartmentIds)
                                setPreviousTagIds(selectedTagIds)
                                setPreviousTypes(selectedTypes)
                                setPreviousGodMode(godMode)
                              }}
                            />
                            {/*
                          <ButtonDropdown.Button
                            icon={faFilePdf}
                            label={t('blog.generatePDF')}
                            className="text-green-300"
                            onClick={(e) => {
                              e.stopPropagation()
                              setOpenExportModal(true)
                              handleGeneratePdfClick()
                            }}
                          />*/}
                            <>
                              <ButtonDropdown.Button
                                icon={faFilter}
                                label={t('general.filter')}
                                className="text-blue-300"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setShowFilter(true)
                                  setPreviousDepartmentIds(
                                    selectedDepartmentIds,
                                  )
                                  setPreviousTagIds(selectedTagIds)
                                  setPreviousTypes(selectedTypes)
                                  setPreviousGodMode(godMode)
                                }}
                              />
                            </>
                          </>
                        </ButtonDropdown.Section>
                      </ButtonDropdown.Menu>
                    </div>
                  )}
                </div>
                {showFilter && (
                  <>
                    <ModalRegular
                      isOpen={showFilter}
                      onClose={() => {
                        setShowFilter(false)
                        setSelectedDepartmentIds(previousDepartmentIds)
                        setSelectedTagIds(previousTagIds)
                        setSelectedTypes(previousTypes)
                        setGodMode(previousGodMode)
                      }}
                      title={t('general.filter')}
                      className={'!max-w-xl pl-4 pt-6 !bg-[#f6f6f6]'}
                      classNameTitle="mb-4 w-full text-black !important flex justify-center "
                    >
                      <div className="flex flex-col divide-y p-4">
                        <ListviewSelections
                          isOpen={openDepartments}
                          onOpen={() => setOpenDepartments(!openDepartments)}
                          selectedIds={selectedDepartmentIds}
                          onClick={(value) => setSelectedDepartmentIds(value)}
                          items={
                            departments?.map((dep) => {
                              return { label: dep.klassName ?? '', id: dep.id }
                            }) ?? []
                          }
                          header={t('admin.departments')}
                          type="list"
                        />
                        <ListviewSelections
                          isOpen={openTags}
                          onOpen={() => setOpenTags(!openTags)}
                          overwriteSelectAll={() => {
                            if (
                              selectedTagIds?.length ===
                                allTagsForEvent?.length ||
                              !selectedTagIds
                            ) {
                              setSelectedTagIds([])
                            } else {
                              setSelectedTagIds(
                                allTagsForEvent?.map((tag) => tag.id),
                              )
                            }
                          }}
                          selectedIds={selectedTagIds}
                          onClick={(value) => setSelectedTagIds(value)}
                          groups={eventTypeSelections.map((eTS) => {
                            return { label: eTS.label, id: eTS.value }
                          })}
                          items={
                            allTagsForEvent?.map((tag) => {
                              return {
                                label: tag.name ?? '',
                                id: tag.id,
                                color: tag.color,
                                icon: faTag,
                                groupId: tag.category,
                              }
                            }) ?? []
                          }
                          header={t('general.tags')}
                          type="list"
                        />
                        <ListviewSelections
                          isOpen={openType}
                          onOpen={() => setOpenType(!openType)}
                          selectedIds={selectedTypes}
                          onClick={(value) =>
                            setSelectedTypes(value as EVENT3_TYPE[])
                          }
                          items={
                            eventTypeSelections?.map((eTS) => {
                              return {
                                label: eTS.label ?? '',
                                id: eTS.value,
                                color: eTS.color,
                                icon: eTS.icon,
                              }
                            }) ?? []
                          }
                          header={t('events.typeOfEvent')}
                          type="list"
                        />

                        {currentUserRole &&
                          currentUserRole.role_type >= USER_ROLE_TYPE.ADMIN && (
                            <div className="p-4">
                              <div className="flex w-full justify-between font-semibold text-sm">
                                <div className="flex flex-col">
                                  <div>{t('events.viewAllEvents')}</div>
                                  <div className="text-xs font-normal">
                                    {t('events.viewAllEventsText')}
                                  </div>
                                </div>
                                <Slider
                                  onBoxClick={() => {}}
                                  onSliderClick={() => {
                                    setGodMode(!godMode)
                                  }}
                                  id={1}
                                  value={godMode}
                                />
                              </div>
                            </div>
                          )}
                      </div>
                      <div
                        className={
                          'flex justify-center w-full bg-gradient-to-t from-[#f6f6f6] sticky bottom-0 left-0 pb-4'
                        }
                      >
                        <Button
                          size="lg"
                          variant="primary"
                          fontSize="font-bold"
                          className="w-[70%]"
                          //loading={loading}
                          label={t('documents.save')}
                          onClick={() => {
                            saveFilters()
                            setShowFilter(false)
                          }}
                        />
                      </div>
                    </ModalRegular>
                  </>
                )}
                {showPrint && (
                  <>
                    <ModalRegular
                      isOpen={showPrint}
                      onClose={() => {
                        setShowPrint(false)
                        setSelectedDepartmentIds(previousDepartmentIds)
                        setSelectedTagIds(previousTagIds)
                        setSelectedTypes(previousTypes)
                        setGodMode(previousGodMode)
                      }}
                      title={t('blog.print')}
                      className={'!max-w-xl pl-4 pt-6 !bg-[#f6f6f6]'}
                      classNameTitle="mb-4 w-full text-black !important flex justify-center "
                    >
                      <div className="flex flex-col divide-y p-4">
                        <div className="p-4">
                          <DateChooser
                            defaultStartDate={printStartDate!}
                            defaultEndDate={printEndDate!}
                            onChangeDate={(startDate: Date, endDate: Date) => {
                              if (endDate < startDate) {
                                endDate = startDate
                              }
                              setPrintStartDate(startDate)
                              setPrintEndDate(endDate)
                            }}
                          />
                        </div>
                        <ListviewSelections
                          isOpen={openDepartments}
                          onOpen={() => setOpenDepartments(!openDepartments)}
                          selectedIds={selectedDepartmentIds}
                          onClick={(value) => setSelectedDepartmentIds(value)}
                          items={
                            departments?.map((dep) => {
                              return { label: dep.klassName ?? '', id: dep.id }
                            }) ?? []
                          }
                          header={t('admin.departments')}
                          type="list"
                        />
                        <ListviewSelections
                          isOpen={openTags}
                          onOpen={() => setOpenTags(!openTags)}
                          overwriteSelectAll={() => {
                            if (
                              selectedTagIds?.length ===
                                allTagsForEvent?.length ||
                              !selectedTagIds
                            ) {
                              setSelectedTagIds([])
                            } else {
                              setSelectedTagIds(
                                allTagsForEvent?.map((tag) => tag.id),
                              )
                            }
                          }}
                          selectedIds={selectedTagIds}
                          onClick={(value) => setSelectedTagIds(value)}
                          groups={eventTypeSelections.map((eTS) => {
                            return { label: eTS.label, id: eTS.value }
                          })}
                          items={
                            allTagsForEvent?.map((tag) => {
                              return {
                                label: tag.name ?? '',
                                id: tag.id,
                                color: tag.color,
                                icon: faTag,
                                groupId: tag.category,
                              }
                            }) ?? []
                          }
                          header={t('general.tags')}
                          type="list"
                        />
                        <ListviewSelections
                          isOpen={openType}
                          onOpen={() => setOpenType(!openType)}
                          selectedIds={selectedTypes}
                          onClick={(value) =>
                            setSelectedTypes(value as EVENT3_TYPE[])
                          }
                          items={
                            eventTypeSelections?.map((eTS) => {
                              return {
                                label: eTS.label ?? '',
                                id: eTS.value,
                                color: eTS.color,
                                icon: eTS.icon,
                              }
                            }) ?? []
                          }
                          header={t('events.typeOfEvent')}
                          type="list"
                        />
                        <ListviewSelections
                          singular
                          isOpen={true}
                          onOpen={() => {}}
                          selectedIds={[typeOfStaffEvent]}
                          onClick={(value) => {
                            if (value && value.length) {
                              setTypeOfStaffEvent(value[0] as StaffEventsRead)
                            }
                          }}
                          items={[
                            {
                              label: t('events.printEventExcludeStaff'),
                              id: 'none_staff_events',
                            },
                            {
                              label: t('events.printEventIncludeStaff'),
                              id: 'all_events',
                            },
                            {
                              label: t('events.printEventOnlyStaff'),
                              id: 'only_staff_events',
                            },
                          ]}
                          header={t('events.printEventStaffHeader')}
                          type="list"
                        />
                      </div>
                      <div
                        className={
                          'flex justify-center w-full bg-gradient-to-t from-[#f6f6f6] sticky bottom-0 left-0 pb-4'
                        }
                      >
                        <Button
                          size="lg"
                          variant="primary"
                          fontSize="font-bold"
                          className="w-[70%]"
                          //loading={loading}
                          label={t('blog.print')}
                          onClick={() => {
                            const typesToQuery =
                              selectedTypes === undefined
                                ? eventTypeSelections.map((eTS) => eTS.value)
                                : selectedTypes
                            if (typesToQuery?.length) {
                              setGeneratingPrint(true)
                              exportEventList({
                                schoolId: currentUserRole?.school_pointer.id!,
                                departmentIds: selectedDepartmentIds,
                                typeOfEvent: typesToQuery,
                                fromDate:
                                  moment(printStartDate).format('YYYY-MM-DD'),
                                toDate:
                                  moment(printEndDate).format('YYYY-MM-DD'),
                                userRoleId: currentUserRole?.id!,
                                languageCode: i18n.language,
                                eventVisibility: typeOfStaffEvent,
                                filterTagIds: selectedTagIds,
                              })
                                .then((response) => {
                                  if (response) {
                                    setCurrentFileJobId(
                                      response.fileJobObjectId,
                                    )
                                  }
                                  setSelectedDepartmentIds(
                                    previousDepartmentIds,
                                  )
                                  setSelectedTagIds(previousTagIds)
                                  setSelectedTypes(previousTypes)
                                  setGodMode(previousGodMode)
                                  setShowPrint(false)
                                })
                                .catch(() => {})
                                .finally(() => {})
                            } else {
                              //Markera att saker måste vara valda
                            }
                          }}
                        />
                      </div>
                    </ModalRegular>
                  </>
                )}
                {generatingPrint && (
                  <SwalCustom
                    isOpen={true}
                    onClose={() => {
                      setGeneratingPrint(false)
                      clearInterval(myTimeout)
                      if (currentFileJobId) {
                        enableProclaimInFileJob(currentFileJobId).finally(
                          () => {
                            setCurrentFileJobId(null)
                          },
                        )
                      }
                    }}
                    showCancelButton={true}
                    showOkButton={false}
                    header={t('blog.fileGenerating')}
                    spinner={true}
                  >
                    <div className="p-10">
                      <>{t('blog.waitCreatePDF')}</>
                    </div>
                  </SwalCustom>
                )}
              </>
            )}
          </div>
        </div>
        {currentMeetings && currentMeetings.length ? (
          <div
            className="flex bg-purpleChildren sticky top-0 p-3 justify-center font-bold text-sm text-white cursor-pointer"
            onClick={() => setOpenMeetingsToBook(true)}
          >
            <div>
              {t('events.booking')} ({currentMeetings.length})
            </div>
          </div>
        ) : null}
        {meetingsToBook && meetingsToBook.length ? (
          <>
            <div className="flex bg-purpleChildren sticky top-0 p-3 justify-center font-bold text-sm text-white cursor-pointer">
              <div
                onClick={() => {
                  setOpenMeetingsToBook(true)
                }}
              >
                {t('events.booking')} ({meetingsToBook.length})
              </div>
            </div>
          </>
        ) : null}
        {openMeetingsToBook && (
          <ModalRegular
            isOpen={openMeetingsToBook}
            onClose={() => setOpenMeetingsToBook(false)}
            hideCloseButton
            className="max-w-[1000px]"
          >
            <MeetingsToBook onClose={() => setOpenMeetingsToBook(false)} />
          </ModalRegular>
        )}
        <div className="flex flex-col md:flex-row flex-1 overflow-hidden">
          <div className="w-full md:w-2/5 md:h-full h-1/3  overflow-auto scrollbar-hide pl-1">
            <Calendar
              multiDates={false}
              changeMonth={async (value: Moment) => {
                await changeCurrentMonth(value)
              }}
              onChangeEvent={() => console.log('')}
              onDateClick={(value, lastDate) => {
                changeSelectedCalendarDate(
                  lastDate === selectedCalendarDate ? undefined : lastDate,
                )
              }}
              dates={selectedCalendarDate ? [selectedCalendarDate] : []}
              currentMonth={currentMonth}
              allowSevenDays={
                currentUserRole?.school_pointer.attributes.school_setting
                  .attributes.eventIs7DayWeek
              }
            />
            {events?.some((ev) => ev.isRead === false) && (
              <div className="flex flex-col flex-wrap mt-1 items-end justify-around gap-y-4 p-2 mr-1 transition-all duration-500 ease-in-out">
                {
                  <>
                    <Button
                      variant="custom"
                      size="sm"
                      textColor="black"
                      fontSize="font-semibold"
                      label={
                        showUnread
                          ? t('general.showAll')
                          : t('notes.showUnread')
                      }
                      icon={showUnread ? faList : faEnvelopeDot}
                      iconColor={showUnread ? 'text-black' : 'text-blueDark'}
                      onClick={() => handleShowAll()}
                    />
                    {
                      <div
                        className={`transform transition-all duration-500 ease-in-out ${
                          animate
                            ? 'translate-y-0 opacity-100'
                            : 'translate-y-[-20px] opacity-0'
                        }`}
                      >
                        <Button
                          variant="custom"
                          textColor="black"
                          size="sm"
                          fontSize="font-semibold"
                          label={t('events.setAsRead')}
                          icon={faMemoCircleCheck}
                          iconColor="text-greenForrest"
                          onClick={() => {
                            setEventsAsRead()
                            handleShowUnread()
                          }}
                        />
                      </div>
                    }
                  </>
                }
              </div>
            )}
          </div>
          <div className="w-full md:w-3/5 md:h-full h-2/3 md:overflow-y-scroll overflow-y-scroll scrollbar-hide pr-2">
            {eventsLoading ? (
              <Loading />
            ) : (
              <Events3
                showUnread={showUnread}
                scrollToEventId={closestEvent?.eventDate!}
              />
            )}{' '}
          </div>
        </div>
      </div>
    </Page>
  )
})
