import React from 'react'
import { makeAutoObservable } from 'mobx'
import Parse, { secret } from 'parse'
import { Department } from '../Models/Department'
import { Kid } from '../Models/Kid'
import { departmentStore } from './departmentStore'
import moment from 'moment'

class StatisticStore {
  statistics: any
  statisticsFromDate?: Date
  statisticsToDate?: Date
  statisticsWeeksOnly: Boolean = false
  constructor() {
    makeAutoObservable(this)
    this.statisticsFromDate = new Date(
      moment()
        //.subtract(1, 'months')
        .startOf('month')
        //.startOf('week')
        //.add(1, 'day')
        .format('YYYY-MM-DD'),
    )
    this.statisticsToDate = new Date(
      moment()
        //.subtract(1, 'months')
        .endOf('month')
        //.endOf('week')
        //.add(1, 'day')
        .format('YYYY-MM-DD'),
    )
  }

  setStatisticsFromDate = async (statisticsFromDate: Date) => {
    this.statisticsFromDate = statisticsFromDate
  }

  setStatisticsToDate = async (statisticsToDate: Date) => {
    this.statisticsToDate = statisticsToDate
  }

  setStatisticsWeeksOnly = async (statisticsWeekOnly: boolean) => {
    this.statisticsWeeksOnly = statisticsWeekOnly
  }

  setStatistics = (statistics: any) => {
    this.statistics = statistics
  }

  setAbsenceOnSchedule = async (kidId: string, date: Date, weekNr: Number) => {
    console.log('kidId', kidId)
    console.log('date', date)
    console.log('weekNr', weekNr)
    const scheduleQuery = new Parse.Query('kids_schedule')
      .equalTo('kid_owner', Kid.createWithoutData(kidId))
      .equalTo('year', moment(date).format('YYYY'))
    const schedule = await scheduleQuery.first()

    if (schedule) {
      let weekToChange = schedule?.get('w_' + weekNr) ?? [
        [null, null, null, null, null],
        [null, null, null, null, null],
        [null, null, null, null, null],
      ]
      weekToChange[0][moment(date).get('day') - 1] = null
      weekToChange[1][moment(date).get('day') - 1] = null

      schedule?.set('w_' + weekNr, weekToChange)
      await schedule?.save()
    } else {
      const kid = await new Parse.Query(Kid).equalTo('objectId', kidId).first()
      const newSchedule = new Parse.Object('kids_schedule')
      if (kid) {
        const lock_schedule = await new Parse.Query('lock_schedule')
          .equalTo('school_pointer', kid.school_pointer)
          .first()
        newSchedule.set('weeks_locked', lock_schedule)
      }
      newSchedule.set('year', moment(date).format('YYYY'))
      newSchedule.set('kid_owner', Kid.createWithoutData(kidId))
      newSchedule.set('school_pointer', kid?.school_pointer)
      newSchedule.set('school', kid?.school)
      let weekToChange: any = [
        [null, null, null, null, null],
        [null, null, null, null, null],
        [null, null, null, null, null],
      ]
      weekToChange[0][moment(date).get('day') - 1] = null
      weekToChange[1][moment(date).get('day') - 1] = null

      newSchedule.set('w_' + weekNr, weekToChange)
      newSchedule.set('has_schedule', true)
      newSchedule.set('kid_name', kid?.kidName)
      await newSchedule.save()
    }
  }

  setFreeOnSchedule = async (kidId: string, date: Date, weekNr: Number) => {
    const scheduleQuery = new Parse.Query('kids_schedule')
      .equalTo('kid_owner', Kid.createWithoutData(kidId))
      .equalTo('year', moment(date).format('YYYY'))
    const schedule = await scheduleQuery.first()

    if (schedule) {
      let weekToChange = schedule?.get('w_' + weekNr) ?? [
        [null, null, null, null, null],
        [null, null, null, null, null],
        [null, null, null, null, null],
      ]

      weekToChange[0][moment(date).get('day') - 1] = 'ledig'
      weekToChange[1][moment(date).get('day') - 1] = 'ledig'
      schedule?.set('w_' + weekNr, weekToChange)
      await schedule?.save()
    } else {
      const kid = await new Parse.Query(Kid).equalTo('objectId', kidId).first()
      const newSchedule = new Parse.Object('kids_schedule')
      if (kid) {
        const lock_schedule = await new Parse.Query('lock_schedule')
          .equalTo('school_pointer', kid.school_pointer)
          .first()
        newSchedule.set('weeks_locked', lock_schedule)
      }
      newSchedule.set('year', moment(date).format('YYYY'))
      newSchedule.set('kid_owner', Kid.createWithoutData(kidId))
      newSchedule.set('school_pointer', kid?.school_pointer)
      newSchedule.set('school', kid?.school)
      let weekToChange: any = [
        [null, null, null, null, null],
        [null, null, null, null, null],
        [null, null, null, null, null],
      ]

      weekToChange[0][moment(date).get('day') - 1] = 'ledig'
      weekToChange[1][moment(date).get('day') - 1] = 'ledig'
      newSchedule.set('w_' + weekNr, weekToChange)
      newSchedule.set('has_schedule', true)
      newSchedule.set('kid_name', kid?.kidName)
      await newSchedule.save()
    }
  }

  createNewLog = async (kidId: string, checkedIn: Date, checkedOut: Date) => {
    const newLog = await Parse.Cloud.run('createNewLog', {
      kidId,
      checkedIn,
      checkedOut,
    })
    return newLog
  }

  getStatistics = async (
    userRoleId: string,
    fromDate: null | Date,
    toDate: null | Date,
    departments: null | Department[] = null,
    kids: null | Kid[] = null,
    schoolId: string,
  ) => {
    const departmentIds: string[] = []
    departments?.map((dep) => {
      departmentIds.push(dep?.id)
    })
    const kidIds: string[] = []
    kids?.map((kid) => {
      kidIds.push(kid?.id)
    })
    const statFromDate = this.statisticsFromDate
    const statToDate = this.statisticsToDate
    const statistics = await Parse.Cloud.run('getStatistics', {
      userRoleId,
      //fromDate,
      //toDate,
      fromDate: statFromDate,
      toDate: new Date(moment(statToDate).format('YYYY-MM-DD 21:59:59')),
      departmentIds,
      kidIds,
      schoolId,
    })
    this.setStatistics(statistics)
  }

  getKidStatistics = async (
    userRoleId: string,
    //fromDate: null | Date,
    //toDate: null | Date,
    departments: null | Department[] = null,
    kids: null | Kid[] = null,
    schoolId: string,
  ) => {
    const departmentIds: string[] = []

    const kidIds: string[] = []
    kids?.map((kid) => {
      kidIds.push(kid.id)
    })

    const statistics = await Parse.Cloud.run('getStatistics', {
      userRoleId,
      fromDate: this.statisticsFromDate,
      toDate: new Date(
        moment(this.statisticsToDate).format('YYYY-MM-DD 21:59:59'),
      ),
      departmentIds,
      kidIds,
      schoolId,
    })
    if (statistics?.kidsList) {
      this.setStatistics(statistics.kidsList[0])
    }
  }
}

export const statisticStore = (() => {
  return new StatisticStore()
})()
export const StatisticStoreContext: React.Context<StatisticStore> =
  React.createContext(statisticStore)
